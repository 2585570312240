<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="yellow darken-2"
          icon="mdi-message-alert"
          title="副反応疑い報告数"
          :value="(items[0].case+items[3].case+items[4].case+items[5].case
            +items[6].case+items[9].case+items[10].case+items[11].case+items[12].case).toLocaleString()"
          :sub-text="subtext_case"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange darken-1"
          icon="mdi-account-alert"
          title="重篤報告数"
          :value="(items[0].serious+items[3].serious+items[4].serious+items[5].serious
            +items[6].serious+items[9].serious+items[10].serious+items[11].serious+items[12].serious).toLocaleString()"
          :sub-text="subtext_serious"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="red darken-1"
          icon="mdi-heart-off"
          title="死亡報告数"
          :value="(items[0].death+items[3].death+items[4].death+items[5].death
            +items[6].death+items[9].death +items[10].death+items[11].death+items[12].death).toLocaleString()"
          :sub-text="subtext_death"
        />
        <!--  P + P5-11 + P-4 + M + T + A -->
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="purple darken-1"
          icon="mdi-heart-off-outline"
          title="因果関係が認定された死亡/副反応件数"
          :value="approvedDeath.toLocaleString() + ' / ' +  approvedCase.toLocaleString()"
          :sub-text="subtext_approved"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <base-material-card color="pink darken-1" class="px-5 py-3">
         <template v-slot:heading>
            <div class="text-h3 font-weight-light">年代別死亡報告数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <apexchart type="bar" height="300" :options="deathsByAgeChartOp" :series="deathsByAgeChartSeries" />
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12" lg="6">
        <base-material-card color="pink darken-1" class="px-5 py-3">
         <template v-slot:heading>
            <div class="text-h3 font-weight-light">接種回数別死亡報告数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <apexchart type="bar" height="300" :options="deathsByCountChartOp" :series="deathsByCountChartSeries" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <base-material-card color="pink darken-1" class="px-5 py-3">
         <template v-slot:heading>
            <div class="text-h3 font-weight-light">年代別心筋炎/心膜炎報告数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <apexchart type="bar" height="300" :options="myocarditisByAgeChartOp" :series="myocarditisByAgeChartSeries" />
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12" lg="6">
        <base-material-card color="pink darken-1" class="px-5 py-3">
         <template v-slot:heading>
            <div class="text-h3 font-weight-light">ワクチン名別心筋炎/心膜炎報告数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <apexchart type="pie" height="300" :options="myocarditisByVaccineNameChartOp" :series="myocarditisByVaccineNameChartSeries" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <base-material-card color="pink darken-1" class="px-5 py-3">
         <template v-slot:heading>
            <div class="text-h3 font-weight-light">予防接種健康被害認定者数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <div style="text-align: right;">
              <p class="d-inline-flex font-weight-light ml-2 mt-1" style="font-size: 14px;">新型コロナワクチン以外は1977/02 - 2021/12の累計データ</p>
            </div>
            <apexchart type="bar" height="300" :options="healthDamageCertChartOp" :series="healthDamageCertChartSeries" />
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12" lg="6">
        <base-material-card color="pink darken-1" class="px-5 py-3">
         <template v-slot:heading>
            <div class="text-h3 font-weight-light">予防接種健康被害認定死者数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <div style="text-align: right;">
              <p class="d-inline-flex font-weight-light ml-2 mt-1" style="font-size: 14px;">新型コロナワクチン以外は1977/02 - 2021/12の累計データ</p>
            </div>
            <apexchart type="bar" height="300" :options="healthDamageCertDeathChartOp" :series="healthDamageCertDeathChartSeries" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="grey darken-1" class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">ワクチン種類別報告数</div>

            <div class="text-subtitle-1 font-weight-light"></div>
          </template>
          <v-card-text>
            <v-data-table :headers="headers" :items="items" :hide-default-footer="true" items-per-page="15">
              <template v-slot:item="i">
                <!-- Since v-slot:item overrides how each row is rendered, I rebuild the row starting from <tr>. This allows me to add a class to <tr> based on any condition I want (in this case, the calorie count) -->
                <tr>
                  <td>
                    <a v-if="i.item.link !==''" :href="i.item.link">{{i.item.id}}</a>
                    <p v-else>{{i.item.id}}</p>
                  </td>
                  <td>{{i.item.name}}</td>
                  <td style="text-align:right">{{i.item.total.toLocaleString()}}</td>
                  <td style="text-align:right">{{i.item.case.toLocaleString()}}</td>
                  <td style="text-align:right">{{i.item.serious.toLocaleString()}}</td>
                  <td style="text-align:right">{{i.item.death.toLocaleString()}}</td>
                  <td
                    style="text-align:right;width:100px"
                  ><div style="width:100px">
                  <div>
                    {{new Intl.NumberFormat('ja', { style: 'percent', maximumSignificantDigits: 3}).format(i.item.death/i.item.total)}}
                    </div>
                    <div style="height:20px;">
                      <div  :style="{width:i.item.death/i.item.total*10000000+'px'}" style="height:20px;background-color: #D50000;"></div>
                    </div></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div
              style="font-size:11px;text-align:right;margin-top:10px"
            >※コミナティ筋注（総数）及びスパイクバックス筋注（総数）の推定接種回数及び副反応疑い報告等の件数については、起源株ワクチン、２価ワクチン及び株不明のワクチンの総数として集計</div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import * as d3 from 'd3'
import apexchart from 'vue-apexcharts'
export default {
  name: 'DashboardDashboard',
  components: {
    apexchart: apexchart,
  },
  data() {
    return {
      approvedDeath: 0,
      approvedCase: 0,
      subtext_case: '',
      subtext_serious: '',
      subtext_death: '',
      subtext_approved: '',

      headers: [
        {
          sortable: false,
          text: '製造業者',
          value: 'id',
          width: '20%',
        },
        {
          sortable: false,
          text: 'ワクチン名',
          value: 'name',
          width: '30%',
        },
        {
          sortable: false,
          text: '総接種回数',
          value: 'total',
          align: 'right',
        },
        {
          sortable: false,
          text: '報告数',
          value: 'case',
          align: 'right',
        },
        {
          sortable: false,
          text: '重篤報告数',
          value: 'serious',
          align: 'right',
        },
        {
          sortable: false,
          text: '死亡報告数',
          value: 'death',
          align: 'right',
        },
        {
          sortable: false,
          text: '死亡報告率',
          align: 'right',
        },
      ],
      items: [],
      deathsByAgeChartOp: {
        colors: ['#F44336', '#E91E63', '#9C27B0'],
        chart: {
          type: 'bar',
        },
        xaxis: { categories: [] },
      },
      deathsByAgeChartSeries: [],

      deathsByCountChartOp: {
        colors: ['#F44336', '#E91E63', '#9C27B0'],
        chart: {
          type: 'bar',
        },
        xaxis: { categories: [] },
      },
      deathsByCountChartSeries: [],

      myocarditisByAgeChartOp: {
        colors: ['#42A5F5', '#EC407A', '#BDBDBD'],
        chart: {
          type: 'bar',
          stacked: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: { categories: [] },
      },
      myocarditisByAgeChartSeries: [],

      myocarditisByVaccineNameChartOp: {
        colors: ['#3F51B5', '#7986CB', '#C5CAE9', '#304FFE', '#E91E63', '#F06292', '#FFC107'],
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
        },
        myocarditisByVaccineNameChartSeries: [],

        healthDamageCertChartOp: {
          colors: ['#FF5722', '#EC407A', '#BDBDBD'],
          chart: {
            type: 'bar',
          },
          xaxis: { categories: [] },
        },
        healthDamageCertChartSeries: [],

        healthDamageCertDeathChartOp: {
          colors: ['#F44336', '#E91E63', '#9C27B0'],
          chart: {
            type: 'bar',
          },
          xaxis: { categories: [] },
        },
        healthDamageCertDeathChartSeries: [],
    }
  },
  mounted() {
    this.getJson()
  },
  methods: {
    getJson() {
      var jsonFileName = 'summary.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          this.items = res.data.items
          this.approvedCase = res.data.approvedCase
          this.approvedDeath = res.data.approvedDeath
          this.subtext_case = res.data.subtext_case
          this.subtext_serious = res.data.subtext_serious
          this.subtext_death = res.data.subtext_death
          this.subtext_approved = res.data.subtext_approved
        }.bind(this),
      )

      jsonFileName = 'death.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          var deaths = res.data
          this.makeCharts(deaths)
        }.bind(this),
      )

      jsonFileName = 'myocarditis.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          var myocarditis = res.data
          this.makeChartsForMyocarditis(myocarditis)
        }.bind(this),
      )

      jsonFileName = 'health_damage_certification.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          var certs = res.data
          this.makeChartsForCert(certs)
          this.makeChartsForCertDeath(certs)
        }.bind(this),
      )
    },

    makeCharts(deaths) {
      // 年齢別の死亡報告数
      var deathsByAge = d3
        .nest()
        .key(function(d) {
          return Math.floor(Number(d.age) / 10) * 10
        })
        .sortKeys(function(a, b) {
            if (b === 'NaN') return -1
            if (Number(a) < Number(b)) return -1
            if (Number(a) > Number(b)) return 1
            return 0
          })
        .rollup(function(v) {
          return d3.sum(v, function(d) {
            return 1
          })
        })
        .entries(deaths)

      this.deathsByAgeChartSeries.push(
        {
          name: '件数',
          data: [],
        },
      )
      for (var i of deathsByAge) {
        if (i.key !== 'NaN') {
          this.deathsByAgeChartOp.xaxis.categories.push(i.key + '代')
        } else {
          this.deathsByAgeChartOp.xaxis.categories.push('不明')
        }
        this.deathsByAgeChartSeries[0].data.push(i.value)
      }

      // 接種回数別の死亡報告数
      const COUNTS = ['1回目', '2回目', '3回目', '4回目', '5回目', '6回目']
      var deathsByCount = d3
        .nest()
        .key(function(d) {
          for (var count of COUNTS) {
            if (d.count.includes(count)) return count
          }
          return '不明'
        })
        .sortKeys(d3.ascending)
        .rollup(function(v) {
          return d3.sum(v, function(d) {
            return 1
          })
        })
        .entries(deaths)

      this.deathsByCountChartSeries.push(
        {
          name: '件数',
          data: [],
        },
      )
      for (var j of deathsByCount) {
        this.deathsByCountChartOp.xaxis.categories.push(j.key)
        this.deathsByCountChartSeries[0].data.push(j.value)
      }
    },
    makeChartsForMyocarditis(myocarditis) {
      // 年齢別の心筋炎報告数
      var myocarditisByAge = d3
        .nest()
        .key(function(d) {
          if (d.age === '') return 'NaN'
          return Math.floor(Number(d.age) / 10) * 10
        })
        .sortKeys(function(a, b) {
            if (b === 'NaN') return -1
            if (Number(a) < Number(b)) return -1
            if (Number(a) > Number(b)) return 1
            return 0
          })
        .key(function(d) {
          if (d.gender === '男性' || d.gender === '女性') return d.gender
          return '不明'
        })
        .sortKeys(function(a, b) {
            if (b === '不明') return -1
            if (a === '男性') return -1
            return 1
          })
        .rollup(function(v) {
          return d3.sum(v, function(d) {
            return 1
          })
        })
        .entries(myocarditis)

        this.myocarditisByAgeChartSeries.push(
        {
          name: '男性',
          data: [],
        },
      )
      this.myocarditisByAgeChartSeries.push(
        {
          name: '女性',
          data: [],
        },
      )
      this.myocarditisByAgeChartSeries.push(
        {
          name: '不明',
          data: [],
        },
      )
      var index = 0
      for (var byGender of myocarditisByAge) {
        if (byGender.key !== 'NaN') {
          this.myocarditisByAgeChartOp.xaxis.categories.push(byGender.key + '代')
        } else {
          this.myocarditisByAgeChartOp.xaxis.categories.push('不明')
        }
        this.myocarditisByAgeChartSeries[0].data.push(0)
        this.myocarditisByAgeChartSeries[1].data.push(0)
        this.myocarditisByAgeChartSeries[2].data.push(0)

        for (var i of byGender.values) {
          if (i.key === '男性') {
            this.myocarditisByAgeChartSeries[0].data[index] = i.value
          } else if (i.key === '女性') {
            this.myocarditisByAgeChartSeries[1].data[index] = i.value
          } else {
            this.myocarditisByAgeChartSeries[2].data[index] = i.value
          }
        }
        index++
      }

      // ワクチン名別の心筋炎報告数
      var myocarditisByVaccineName = d3
        .nest()
        .key(function(d) {
          return d.vaccine_name
        })
        // .sortKeys(function(a, b) {
        //     if (b === 'NaN') return 1
        //     if (Number(a) < Number(b)) return -1
        //     if (Number(a) > Number(b)) return 1
        //     return 0
        //   })
        .rollup(function(v) {
          return d3.sum(v, function(d) {
            return 1
          })
        })
        .entries(myocarditis)

      for (var j of myocarditisByVaccineName) {
        this.myocarditisByVaccineNameChartOp.labels.push(j.key)
        this.myocarditisByVaccineNameChartSeries.push(j.value)
      }
    },

    makeChartsForCert(certs) {
      this.healthDamageCertChartSeries.push(
        {
          name: '件数',
          data: [],
        },
      )

      for (var cert of certs) {
        this.healthDamageCertChartOp.xaxis.categories.push(cert.name)
        this.healthDamageCertChartSeries[0].data.push(cert.total)
      }
      this.healthDamageCertChartOp.xaxis.categories.push('新型コロナワクチン')
        this.healthDamageCertChartSeries[0].data.push(this.approvedCase)
    },

    makeChartsForCertDeath(certs) {
      this.healthDamageCertDeathChartSeries.push(
        {
          name: '件数',
          data: [],
        },
      )

      for (var cert of certs) {
        this.healthDamageCertDeathChartOp.xaxis.categories.push(cert.name)
        this.healthDamageCertDeathChartSeries[0].data.push(cert.death)
      }
      this.healthDamageCertDeathChartOp.xaxis.categories.push('新型コロナワクチン')
        this.healthDamageCertDeathChartSeries[0].data.push(this.approvedDeath)
    },
  },
}
</script>
